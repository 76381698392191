<template>
    <div class="h-screen flex flex-col pt-20 sm:px-6 lg:px-8 bg-[#F3F4F6]">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Přihlášení</h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Ještě u nás nemáš účet?
                {{ ' ' }}
                <router-link to="/register" class="font-medium text-indigo-600 hover:text-indigo-500">Registruj se
                    zde.</router-link>
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form class="space-y-6">
                    <!-- Email -->
                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <input v-model="email.value" type="email" name="email" id="email"
                                :class="{ 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 placeholder-red-300': email.error }"
                                class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border-gray-300"
                                placeholder="jan.novak@email.cz" aria-invalid="true" aria-describedby="email-error" />
                            <div v-if='email.error'
                                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                        </div>
                        <p v-if='email.error' class="mt-2 text-sm text-red-600" id="email-error">{{ email.error }}</p>
                    </div>

                    <!-- Password -->
                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <input v-model="password.value" type="password" name="password" id="password"
                                :class="{ 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 placeholder-red-300': password.error }"
                                class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border-gray-300"
                                placeholder="*****" aria-invalid="true" aria-describedby="password-error" />
                            <div v-if='password.error'
                                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                        </div>
                        <p v-if='password.error' class="mt-2 text-sm text-red-600" id="email-error">{{ password.error }}</p>
                    </div>

                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox"
                                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                            <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Pamatovat si mě</label>
                        </div>

                    </div>

                    <div>
                        <router-link to="/home">
                            <button @click="login"
                                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Přihlásit
                                se</button>
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
const axios = require('axios');
import { config } from './../config.js';
import { Iodine } from '@kingshott/iodine';
import { ExclamationCircleIcon } from '@heroicons/vue/solid';
import { useComposition } from '@/compositions/composition';

export default {
    name: 'RegisterView',
    components: {
        ExclamationCircleIcon
    },
    setup() {
        const { messages } = useComposition();

        return {
            messages
        }
    },
    data() {
        return {
            iodine: new Iodine(),
            email: {
                value: '',
                error: null
            },
            password: {
                value: '',
                error: null
            },
        }
    },
    methods: {
        login(e) {
            e.preventDefault();

            if (!this.iodine.isEmail(this.email.value))
                this.email.error = 'Zadejte platný email';
            else
                this.email.error = null;

            if (!this.iodine.isMaxLength(this.password.value, 255) || !this.iodine.isMinLength(this.password.value, 5))
                this.password.error = 'Heslo musí být v rozsahu 5 až 255 znaků';
            else
                this.password.error = null;

            // Pošle registrační požadavek
            axios({
                method: "post",
                url: `${config.backend}/auth/in`,
                dataType: "JSON",
                withCredentials: true,
                data: {
                    email: this.email.value,
                    password: this.password.value
                }
            }).then(response => {
                // Pokud vše proběhlo v pořádku
                if (response.data.result == 'success')
                    // Redirect
                    this.$router.push('/home');
            }).catch(error => {
                // Vypíše chybu
                console.log(error);

                this.messages.push({
                    type: 'error',
                    title: "Chyba",
                    content: "Heslo nebo e-mail nejsou správné, nebo e-mail není ověřený.",
                    show: true
                });
            });
        }
    }
}
</script>
